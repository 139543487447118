import http from '@/utils/request'


// 获取客户端下载连接
export const getDownloadUrl = (body) => {
  const url = '/website/getDownloadUrl'
  return http.post(url, body, http.formHeaders)
}


// 获取播放视频地址
export const getVideoUrl = (body) => {
  const url = '/website/getVideoUrl'
  return http.post(url, body, http.formHeaders)
}


// 统计ip日活
export const getUserIp = (body) => {
  const url = '/website/getUserIp'
  return http.post(url, body, http.formHeaders)
}
