import axios from 'axios'
import Vue from 'vue'

axios.defaults.withCredentials=true
const http = axios.create()

// 自定义http的相关变量
http.formHeaders = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
// http.formHeaders = { headers: { 'Content-Type': 'application/json' } }
http.getHeaders = { headers: {} }
http.multiFile = { headers: { 'Content-Type': 'multipart/form-data' } }


// http.host = process.env.NODE_ENV==='development'?'https://www.codingsee.com/api':'https://www.codingsee.com/api'
http.host = process.env.NODE_ENV==='development'?'http://127.0.0.1:8888':'/api'
// http.host = process.env.NODE_ENV==='development'?'http://192.168.0.107:8888':'http://127.0.0.1:8888'


// 请求拦截器
http.interceptors.request.use(config => {
  config.baseURL = http.host
  return config
})

// 响应拦截器
http.interceptors.response.use(response => {
    if (response.status === 200) {
      switch (response.data.code) {
        case 0:
          // retSuccess(response.data.msg)
          return response.data;
        case 1:
          return Promise.reject(response);
        case 2:
          return Promise.reject(response);
        case 3:
          return Promise.reject(response);
        case 4:
          return Promise.reject(response);
        case 5:
          return Promise.reject(response);
        case 6:
          return Promise.reject(response);

      }
    }else{
      return Promise.reject(response);
    }


  },
  error => {
    Vue.prototype.$message.error({
      message: '服务器请求失败',
      center: true
    })
    return Promise.reject(error)
  }
)


export default http
