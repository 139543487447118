<template>
  <div id="app">
    <div class="core-page" ref="corePage">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>
import {getUserIp} from "@/apis/website";

export default {
  name: '',
  data() {
    return {

    }
  },

  mounted() {
    // 统计ip访问量
    // 如果今天没有统计过，则发送请求，统计用户当日活跃
    // let date = new Date()
    // let year = date.getFullYear()
    // let month = String(date.getMonth() + 1).padStart(2, '0')
    // let day = String(date.getDate()).padStart(2, '0')
    // let today = `${year}-${month}-${day}`
    //
    // let checkDate = localStorage.getItem("checkDate")
    // if (checkDate === null || checkDate !== today) {
    //     // 没有统计
    //     dailyUse({type: 1}).then(res=>{
    //         // 设置为已统计
    //         localStorage.setItem("checkDate", today)
    //     })
    // }

    // 只要访问了就算
    getUserIp()

  },

  destroyed() {

  },

  methods: {



  },

  computed: {},

  watch: {}
}
</script>


<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  //min-height: 500px;

  .core-page {
    width: 100%;
    height: 100%;
    position: relative;


  }

}

</style>
